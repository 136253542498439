<template>
  <config-wrapper
    :header="item.name"
    :desc="item.desc"
    @configUpdated="configUpdated"
  >
    <span
      v-for="(fld, index) in item.field"
      :key="index"
    >
      <vs-checkbox
        class="inline-flex "
        style="font-size:medium;"
        :vs-value="fld"
        v-model="item.value"
      >
        {{ fld }}
      </vs-checkbox>
    </span>
  </config-wrapper>
</template>

<script>
import ConfigWrapper from "@/app/shared/shared-components/configComponents/ConfigWrapper";

export default {
  name: "CheckboxConfig",
  components: {ConfigWrapper},
  methods: {
    configUpdated() {
      this.$emit('configUpdated', this.item);
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
  },
};
</script>
